






























































import Vue from 'vue';
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {ENDPOINTS, get, post} from "@/api/mainApi";
import {OrganizationRegistrationPage} from "@/interfaces/organization/organizationRegistrationPage";
import {Organization} from "@/interfaces/organization/organization";

export default Vue.extend({
  components: {},
  props: {
    organization: Object as () => Organization,
  },
  data() {
    return {
      newOrganizationRegistrationPage: {
        nameUrlSegment: "",
        welcomeText: "",
        organization: this.organization,
        publishedBy: null
      } as OrganizationRegistrationPage | null,
      timerId: 0,
      loading: false,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält',
        v => v.trim().length <= 20 || 'Segmentet måste ha 20 bokstäver eller färre',
        v => v.trim().length >= 3 || 'Segmentet måste ha fler än 3 bokstäver',
        v => !v.includes(" ") || 'Segmentet får inte ha några blanksteg',
        v => /^[a-z0-9]+$/.test(v) || 'Segmentet får inte innehålla Å,Ä,Ö, stora bokstäver eller några speciella symboler såsom _',
      ],
      emptyRules: [
        v => (!(v.trim().length === 0)) || 'Fältet får inte vara tomt'
      ],
      regFormValid: false,
      url: ""
    }
  },
  computed: {
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    organizationRegistrationPage: {
      get: function (): OrganizationRegistrationPage {
        return this.value;
      },
      set: function (newValue: OrganizationRegistrationPage) {
        this.$emit('update:value', newValue)
      }
    },
  },
  watch: {
    'newOrganizationRegistrationPage': {
      handler (newValue: OrganizationRegistrationPage, oldValue: OrganizationRegistrationPage) {
        this.fetchUrlDebounced();
      },
      deep: true
    },
    organization(value: Organization) {
      if (value) {
        this.newOrganizationRegistrationPage.publishedBy = this.stateUser;
      }
    }
  },
  created() {
  },
  methods: {
    async createOrganizationRegistrationPage(organizationRegistrationPage: OrganizationRegistrationPage) {

      let valid = this.$refs.regForm.validate()

      if (organizationRegistrationPage && valid) {

        organizationRegistrationPage.publishedBy = this.stateUser;

        this.loading = true;

        try {
          let updateResponse = await post(ENDPOINTS.ORGANIZATIONS + "/" + this.organization.id + "/" + ENDPOINTS.REGISTRATION_PAGE, organizationRegistrationPage);

          if (updateResponse) {
            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Publicerade registreringssida",
              timeout: 6000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);

            this.newOrganizationRegistrationPage = {
              nameUrlSegment: "",
                welcomeText: "",
                organization: this.organization,
                publishedBy: this.stateUser
            } as OrganizationRegistrationPage | null;

            this.$emit("close", true);
          }
        } catch (err) {
          console.log(err);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att publicera registreringssida: " + err.data.msg,
            timeout: 10000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        } finally {
          this.loading = false;
        }
      }
    },
    async getGeneratedRegistrationUrl() {
      try {
        let response = await get(ENDPOINTS.REGISTRATION_PAGE + "/" + ENDPOINTS.GENERATE + "/" + ENDPOINTS.URL +
          "?organizationId=" + this.organization.id +
          "&urlSegment=" + this.newOrganizationRegistrationPage.nameUrlSegment);

        if (response) {
          this.url = response;
        }
      } catch (e) {
        console.log(e);
      }
    },
    fetchUrlDebounced() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getGeneratedRegistrationUrl()
      }, 300);
    },
    deleteOrganizationRegistrationPage(organizationRegistrationPage: OrganizationRegistrationPage) {
      this.$emit("delete", organizationRegistrationPage)
    }
  }
})
