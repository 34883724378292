




















import Vue from "vue";
import {TableOptions} from "@/interfaces/Vuetify";
import {SparStatisticsSummary} from "@/interfaces/spar/sparStatisticsSummary";
import {ENDPOINTS, get} from "@/api/mainApi";
import dayjs from 'dayjs';

export default Vue.extend({
    data() {
        return {
            loading: false,
            interval: null,
            intervalPollTime: 120000,
            startDelayPollTime: 120000,
            fromDate: dayjs().subtract(2, 'month').toISOString(),
            sparStatisticsSummaries: [] as Array<SparStatisticsSummary>,
            footerProps: {
                "items-per-page-all-text": "alla",
                "items-per-page-options": [50, 100, 150],
                "items-per-page-text": "Rader per sida"
            },
            tableHeaders: [
                {
                    text: 'Skapad',
                    value: "createdAt",
                    align: 'left',
                    sortable: true,
                },
                {
                    text: 'Antal avlidna',
                    value: "deceasedCount",
                    align: 'left',
                    sortable: true,
                },
                {
                    text: 'Antal oöppnade',
                    value: "unopenedCount",
                    align: 'left',
                    sortable: true,
                }
            ],
            optionsProps: {
                sortDesc: [false],
                multiSort: false
            } as TableOptions,

        };
    },
    mounted() {
      this.getStatistics();
      this.poll(this.getStatistics, this.intervalPollTime, this.startDelayPollTime);
    },
    beforeDestroy()
    {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    methods: {
        async getStatistics() {

          let statsResponse = await get(ENDPOINTS.STATISTICS + "/" + ENDPOINTS.FRONTPAGE, {fromDate: this.fromDate});

          if (statsResponse)
          {
            this.sparStatisticsSummaries = statsResponse;
          }
        },
        poll(fn, intervalTime, startAfter)
        {
          setTimeout(() =>
          {
            if (this.interval) {
              clearInterval(this.interval);
            }

            this.interval = setInterval(() =>
            {
              fn();
            }, intervalTime)
          }, startAfter)
        },
    }
});
