


























































































































































import Vue from "vue";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {BASE_URL} from "@/api/mainApi";
import {RoleStr} from "@/enums/role";
import Avatar from 'vue-avatar-component';

export default Vue.extend({
  components: {
    Avatar
  },
  filters: {
    getRoleAsString(role, roleStr): string {
      return roleStr[role];
    },
  },
  props: {
    dialogValue: Boolean,
    userAccounts: Array as () => Array<UserAccount>,
  },
  data() {
    return {
      roleStr: RoleStr,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält',
      ],
      selectedUserAccount: null as UserAccount | null
    }
  },
  computed: {
    sortedUserAccounts(): Array<UserAccount> {
      let userAccounts = [...this.userAccounts];
      userAccounts.forEach((u: UserAccount) => {
        if (!u.activated) {
          u.disabled = true;
        }
      })


      return userAccounts.sort((u1) => u1.id === this.user.id ? -1 : 1)
    },
    user(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    active: {
      get(): boolean {
        return this.dialogValue
      },
      set(dialogValue: boolean) {
        this.$emit('update:dialogValue', dialogValue)
      }
    }
  },
  methods: {
    setCurrentSelectedUser() {
      //is used by parent component
      if (this.user) {
        this.selectedUserAccount = this.userAccounts.find(u => u.id === this.user.id)
      }
    },
    userAvatar(user): string {
      if (user.fileData && user.fileData.src) {
        return BASE_URL + user.fileData.src;
      }
      return '';
    },
    saved() {
      if (this.$refs.form.validate()) {
        this.$emit('saved', this.selectedUserAccount)
        this.closeDialog()
      }
    },
    userIsActivated(user: UserAccount): boolean {
      return !!user.activationStatus && user.activationStatus.activationStatusType === 'ACTIVATED';
    },
    userIsDisabled(user: UserAccount): boolean {
      return !!user.disabledStatus;
    },
    closeDialog() {
      this.active = false
    },
  }
});
