




































































import Vue from "vue";
import {Keepsake} from "@/interfaces/keepsake/keepsake";
import {FileData} from "@/interfaces/fileData";
import FileUploadComponent from "@/components/FileUploadComponent.vue";
import {ENDPOINTS, put} from "@/api/mainApi";
import {Snackbar} from "@/interfaces/presentation/snackbar";

export default Vue.extend({
  components: {
    FileUploadComponent
  },
  data() {
    return {
      loading: false,
      menu: false,
      fileData: null
    }
  },
  props: {
    keepsake: Object as () => Keepsake,
    xSmall: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    item: {
      get(): Keepsake {
        return this.keepsake
      },
      set(val: Keepsake) {
        this.$emit('update:keepsake', val)
      },
    },
    icon() {
      return this.menu ? "mdi-close" : "mdi-file-alert-outline"
    },
    color() {
      return this.menu ? "grey" : "info"
    }
  },
  watch: {
    menu(val) {
      if(!val)
      {
        this.$refs.fileupload.clearFile()
      }
    }
  },
  methods: {
    fileUploaded(fileData: FileData) {
      this.fileData = Object.assign({}, fileData)
    },
    cancelUpload() {
      this.fileData = null
      this.item.fileData = null
      this.menu = false
    },
    async save() {
      this.loading = true
      try {
        this.item.fileData = this.fileData
        let response = await put(ENDPOINTS.KEEPSAKES + "/" + this.item.id + "/" + ENDPOINTS.ATTACHED_FILE, this.fileData)
        if (response && response.id) {
          this.item = response
          let snackbar: Snackbar = {
            active: true,
            color: "success",
            text: "Dokument sparad!",
            timeout: 6000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
          this.menu = false
        }
      } catch (e) {
        console.error(e)
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Något gick fel när dokumentet skulle sparas. Kontakta support om problemet kvarstår",
          timeout: 10000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
      }
      this.loading = false
    }
  }
});
