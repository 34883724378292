






















































































































































































































































































import Vue from 'vue';
import {Organization} from "@/interfaces/organization/organization";
import {Business} from "@/interfaces/business/business";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import OfficeAdminComponent from "@/components/administration/OfficeAdminComponent.vue";
import BusinessEditOtherComponent from "@/components/administration/BusinessEditOtherComponent.vue";
import {deleteRequest, ENDPOINTS, get, post, put} from "@/api/mainApi";
import {Office} from "@/interfaces/office/office";
import {Dialog} from "@/interfaces/presentation/dialog";
import {NotificationSettings, NOTIFICATIONTYPE} from "@/interfaces/notificationsettings/notificationSettings";

export default Vue.extend({
  components: {
    OfficeAdminComponent, BusinessEditOtherComponent
  },
  props: {
    selectedOrganization: Object as () => Organization
  },
  data() {
    return {
      searchBusiness: "",
      businessMoveStep: 1, // used to control when to show close button.
      defaultNotificationDelay: {
        name: "Delay deceased unopened depot",
        notificationDelayDays: 7,
        notificationType: NOTIFICATIONTYPE.UNOPENED_DEPOT_WITH_DECEASED,
        business: null
      },
      businesses: [] as Business[],
      filteredBusinesses: [] as Business[],
      selected: null as Business | null,
      loading: false,
      newBusiness: {
        name: "",
        phone: "",
        email: "",
        organization: this.selectedOrganization,
      } as Business | null,
      newDialog: false,
      editOfficesDialog: false,
      editBusinessSettings: false,
      emailRules: [
        v => !v || /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
      ],
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      newFormValid: false,
      selectedFormValid: false,
      unclaimedNumberErrorMessage: ""
    }
  },
  computed: {
    isMovingBusiness(): boolean {
      // Dialog should not be closeable when moving business.
      return this.businessMoveStep === 2 || this.businessMoveStep === 3;
    }
  },
  watch: {
    selectedOrganization(val: Organization) {
      if (val && val.id) {
        this.selected = null;
        this.getBusinesses(val.id);
        this.newBusiness.organization = val;
      }
    },
      searchBusiness(val: string) {
        if (val && val.length > 0) {
          this.filteredBusinesses = this.businesses.filter((business: Business) => {
            return business.name.toLowerCase().includes(val.toLowerCase());
          });
        } else {
          this.filteredBusinesses = this.businesses;
        }
      }
  },
  methods: {
    setInitialBusiness(){
      this.$emit("setInitialBusiness");
    },
    selectBusiness(business: Business) {
      this.loading = true;
      this.selected = business;

      get(ENDPOINTS.BUSINESSES + "/" + business.id + "/" + ENDPOINTS.OFFICES)
        .then((offices: Array<Office>) => {
          this.selected.offices = offices;
        }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.$emit('select', business);
        this.businesses.forEach(o => o.active = false);
        business.active = true;
      })

      this.getNotificationSettings(business)
    },
    async getNotificationSettings(business: Business) {
      get(ENDPOINTS.BUSINESSES + "/" + business.id + "/" + ENDPOINTS.NOTIFICATION_SETTINGS,
      ).then((notificationSettings: Array<NotificationSettings>) => {
        business.notificationSettings = notificationSettings;
      }).catch(err => {
        console.log(err);
        this.createDefaultNotificationDelay(business);
      }).finally(() => {
        this.loading = false;
      })
    },
    async createDefaultNotificationDelay(business: Business) {
      this.loading = true;
      this.defaultNotificationDelay.business = business;

      post(ENDPOINTS.NOTIFICATION_SETTINGS, this.defaultNotificationDelay)
        .then((notificationSettings: NotificationSettings) => {
          business.notificationSettings = new Array<NotificationSettings>();
          business.notificationSettings.push(notificationSettings);

          this.creatingNew = false;
        }).catch(err => {
        console.log(err);
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Misslyckades med att skapa företagsinställningar: " + err,
          timeout: 10000,
          action: null
        }
        this.$store.commit('setSnackbar', snackbar)
      }).finally(() => {
        this.loading = false;
        this.creatingNew = false;
        this.selected = business;
      })
    },
    autoAddedStoragePlace(office: Office) {
      this.$emit("auto-added-storage-place", office)
    },
    async getBusinesses(organizationId) {
      this.loading = true;
      get(ENDPOINTS.ORGANIZATIONS + "/" + organizationId + "/" + ENDPOINTS.BUSINESSES,
      ).then((businesses: Array<Business>) => {
        this.businesses = businesses;
        this.filteredBusinesses = businesses;
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateBusiness(business: Business) {

      this.$refs.selectedForm.validate();

      if (this.selectedFormValid) {

        this.loading = true;

        put(ENDPOINTS.BUSINESSES + "/" + business.id, business)
          .then((business: Business) => {
            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Sparade ändringar för " + business.name,
              timeout: 8000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
            this.newDialog = false;
          }).catch(err => {
          console.log(err.data.msg);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att uppdatera företag: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    addBusiness() {
      this.$refs.newForm.validate();

      if (this.newFormValid) {

        this.loading = true;

        post(ENDPOINTS.BUSINESSES, this.newBusiness)
          .then((business: Business) => {
            this.businesses.push(business);

            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "La till " + this.newBusiness.name,
              timeout: 8000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar);

            this.newBusiness = {
              name: "",
              email: "",
              phone: "",
              organization: this.selectedOrganization
            } as Business
          }).catch(err => {
          console.log(err.data.msg);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att lägga till företag: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        }).finally(() => {
          this.loading = false;
          this.newDialog = false
        })
      }
    },
    confirmDeactivateBusiness(business: Business) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Inaktivera företag",
        title: "Bekräfta inaktivering",
        text: "Är du säker på att du vill inaktivera " + business.name + "?",
        actionClick: () => {
          this.deactivateBusiness(business)
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    async deactivateBusiness(business: Business) {

      this.loading = true;

      try {
        let deleted = await deleteRequest(ENDPOINTS.BUSINESSES + "/" + business.id);

        if (deleted) {

          let index = this.businesses.findIndex((v: Business) => v.id == business.id)
          if (index !== -1) {
            this.businesses.splice(index, 1);
            this.selected = null;

            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Inaktiverade företaget",
              timeout: 6000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar)
          }
        }
      } catch (err) {
        console.log(err);
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Misslyckades med att inaktivera företaget: " + err.data.msg,
          timeout: 10000,
          action: null
        }
        this.$store.commit('setSnackbar', snackbar)
      } finally {
        this.loading = false;
      }
    },
  }
})
