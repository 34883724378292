











































































































import Vue from 'vue';
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {RoleArray} from "@/enums/role";
import {Dialog} from "@/interfaces/presentation/dialog";
import {ENDPOINTS, get, put} from "@/api/mainApi";
import {OrganizationRegistrationPage} from "@/interfaces/organization/organizationRegistrationPage";
import {Organization} from "@/interfaces/organization/organization";
import log from "loglevel";
import {ExternalAgent} from "@/interfaces/externalagent/externalAgent";

export default Vue.extend({
  components: {},
  props: {
    value: Object as () => OrganizationRegistrationPage
  },
  data() {
    return {
      roles: RoleArray,
      loading: false,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält',
        v => v.trim().length <= 20 || 'Segmentet måste ha 20 bokstäver eller färre',
        v => v.trim().length >= 3 || 'Segmentet måste ha fler än 3 bokstäver',
        v => !v.includes(" ") || 'Segmentet får inte ha några blanksteg',
        v => /^[a-z0-9]+$/.test(v) || 'Segmentet får inte innehålla Å,Ä,Ö, stora bokstäver eller några speciella symboler såsom _',
      ],
      regFormValid: false,
      url: ""
    }
  },
  computed: {
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    organizationRegistrationPage: {
      get: function (): OrganizationRegistrationPage {
        return this.value;
      },
      set: function (newValue: OrganizationRegistrationPage) {
        this.fetchUrlDebounced();
        this.$emit('update:value', newValue)
      }
    },
  },
  watch: {
    'organizationRegistrationPage': {
      handler (newValue: OrganizationRegistrationPage, oldValue: OrganizationRegistrationPage) {
        this.fetchUrlDebounced();
      },
      deep: true,
      immediate: true
    },
  },
  created() {
  },
  methods: {
    async updateOrganizationRegistrationPage(organizationRegistrationPage: OrganizationRegistrationPage) {

      let valid = this.$refs.regForm.validate()

      if (organizationRegistrationPage && valid) {

        this.loading = true;

        try {
          let updateResponse = await put(ENDPOINTS.REGISTRATION_PAGE + "/" + organizationRegistrationPage.id, organizationRegistrationPage);

          if (updateResponse) {
            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Sparade ändringar för registreringssidan",
              timeout: 6000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
          }
        } catch (err) {
          console.log(err);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att spara ändringarna: " + err.data.msg,
            timeout: 10000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        } finally {
          this.loading = false;
        }
      }
    },
    confirmDeleteOrganizationRegistrationPage(organizationRegistrationPage: OrganizationRegistrationPage) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Inaktivera registreringssida",
        title: "Bekräfta inaktivering",
        text: "Är du säker på att du vill inaktivera sidan? Den kommer inte längre att vara åtkomlig för användare",
        actionClick: () => {
          this.deleteOrganizationRegistrationPage(organizationRegistrationPage)
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    async getGeneratedRegistrationUrl() {
      try {
        let response = await get(ENDPOINTS.REGISTRATION_PAGE + "/" + ENDPOINTS.GENERATE + "/" + ENDPOINTS.URL +
          "?organizationId=" + this.organizationRegistrationPage.organization.id +
          "&urlSegment=" + this.organizationRegistrationPage.nameUrlSegment);

        if (response) {
          this.url = response;
        }
      } catch (e) {
        console.log(e);
      }
    },
    copyLink() {
      let textToCopy = this.$refs.link.$el.querySelector('input')
      textToCopy.select()
      document.execCommand("copy");

      let snackbar: Snackbar = {
        active: true,
        color: "primary",
        text: "Kopierade registreringslänken",
        timeout: 6000,
        action: null
      }
      this.$store.commit('setSnackbar', snackbar)
    },
    fetchUrlDebounced() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getGeneratedRegistrationUrl()
      }, 300);
    },
    deleteOrganizationRegistrationPage(organizationRegistrationPage: OrganizationRegistrationPage) {
      this.$emit("delete", organizationRegistrationPage)
    },
    confirmEdit(organizationRegistrationPage: OrganizationRegistrationPage) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Uppdatera",
        title: "Bekräfta uppdatering",
        text: "Är du säker på att du vill uppdatera registreringssidan? Om du ändrat på länkdelen så kommer gamla länkar att bli ogiltiga.",
        actionClick: () => {
          this.updateOrganizationRegistrationPage(organizationRegistrationPage)
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    confirmDisable(organizationRegistrationPage: OrganizationRegistrationPage) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Inaktivera",
        title: "Bekräfta inaktivering",
        text: "Är du säker på att du vill inaktivera registreringssidan? Den kommer inte längre att vara åtkomlig för externa användare",
        actionClick: () => {
          this.disable(organizationRegistrationPage)
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    confirmEnable(organizationRegistrationPage: OrganizationRegistrationPage) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Aktivera",
        title: "Bekräfta aktivering",
        text: "Är du säker på att du vill återaktivera registreringssidan? Den kommer då vara åtkomlig för externa användare",
        actionClick: () => {
          this.enable(organizationRegistrationPage)
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    async disable(organizationRegistrationPage: OrganizationRegistrationPage) {

      this.loading = true;

      try {
        let updateResponse = await put(ENDPOINTS.REGISTRATION_PAGE + "/" + organizationRegistrationPage.id + "/" + ENDPOINTS.DISABLE, organizationRegistrationPage);

        if (updateResponse) {
          this.organizationRegistrationPage.active = false;
          let snackbar: Snackbar = {
            active: true,
            color: "success",
            text: "Inaktiverade registreringssidan",
            timeout: 6000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        }
      } catch (err) {
        console.log(err);
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Misslyckades med att inaktivera sidan: " + err.data.msg,
          timeout: 10000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
      } finally {
        this.loading = false;
      }
    },
    async enable(organizationRegistrationPage: OrganizationRegistrationPage) {

      this.loading = true;

      try {
        let updateResponse = await put(ENDPOINTS.REGISTRATION_PAGE + "/" + organizationRegistrationPage.id + "/" + ENDPOINTS.ENABLE, organizationRegistrationPage);

        if (updateResponse) {
          this.organizationRegistrationPage.active = true;
          let snackbar: Snackbar = {
            active: true,
            color: "success",
            text: "Aktiverade registreringssidan",
            timeout: 6000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        }
      } catch (err) {
        console.log(err);
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Misslyckades med att aktivera sidan: " + err.data.msg,
          timeout: 10000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
      } finally {
        this.loading = false;
      }
    }
  }
})
