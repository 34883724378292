import {DepotKeepsakeContainer} from "@/interfaces/keepsake/depotKeepsakeContainer.ts";
import {EventLog} from "@/interfaces/eventlog/eventLog";

export interface Depot {
  id: number;
  createdAt: string;
  statusUpdatedAt: string;
  name: string;
  socialSecurityNumber: string;
  keepsakes: Array<DepotKeepsakeContainer>;
  status: Status;
  lastEventCreatedAt?: string;
  createdBy: number | null;
  deceased?: boolean;
  eventLogs?: Array<EventLog>;
  adminRoleRequired?: boolean;
  legacyId: number | null;
  deceasedRegistrationDate: string | null;
  deceasedDate: string | null;
  amnesty: boolean;
  plannedToBeArchivedAt: Date | null;
}

export enum Status {
  ADDED = "ADDED",
  MATCHED_WITH_SPAR = "MATCHED_WITH_SPAR",
  DELISTED_WITH_SPAR = "DELISTED_WITH_SPAR",
  MARKED_AS_DECEASED_BY_USER = "MARKED_AS_DECEASED_BY_USER",
  MARKED_AS_DECEASED_BY_SPAR = "MARKED_AS_DECEASED_BY_SPAR",
  DECEASED_WORK_IN_PROGRESS = "DECEASED_WORK_IN_PROGRESS",
  ARCHIVED = "ARCHIVED",
  UNEXPECTED = "UNEXPECTED",
  DELETED = "DELETED",
  DECEASED_PROTECTED_IDENTITY = "DECEASED_PROTECTED_IDENTITY",
  DECEASED_WORK_IN_PROGRESS_PROTECTED_IDENTITY = "DECEASED_WORK_IN_PROGRESS_PROTECTED_IDENTITY",
}

export enum StatusStr {
  ADDED = "Väntar på svar från SPAR",
  MATCHED_WITH_SPAR = "Under bevakning hos SPAR",
  DELISTED_WITH_SPAR = "Avregistrerad hos SPAR (Förmodligen utflyttad)",
  MARKED_AS_DECEASED_BY_USER = "Anmäld som avliden av användare",
  MARKED_AS_DECEASED_BY_SPAR = "Avliden (Träff hos SPAR)",
  DECEASED_WORK_IN_PROGRESS = "Avliden - Under arbete",
  ARCHIVED = "Arkiverad",
  UNEXPECTED = "Oväntad Sparstatus",
  DELETED = "Raderad",
  DECEASED_PROTECTED_IDENTITY = "Skyddad identitet",
  DECEASED_WORK_IN_PROGRESS_PROTECTED_IDENTITY = "Skyddad identitet - Under arbete"
}

export enum ShortenedStatusStr {
  ADDED = "Väntar på svar",
  MATCHED_WITH_SPAR = "Under bevakning",
  DELISTED_WITH_SPAR = "Avregistrerad",
  MARKED_AS_DECEASED_BY_USER = "Anmäld som avliden",
  MARKED_AS_DECEASED_BY_SPAR = "Avliden (SPAR)",
  DECEASED_WORK_IN_PROGRESS = "Avliden - Under arbete",
  ARCHIVED = "Arkiverad",
  UNEXPECTED = "Oväntad Sparstatus",
  DELETED = "Raderad",
  DECEASED_PROTECTED_IDENTITY = "Skyddad identitet",
  DECEASED_WORK_IN_PROGRESS_PROTECTED_IDENTITY = "Skyddad identitet - Under arbete"
}

export enum StatusDescription {
  ADDED = "Depån har lagts till. Någon information har ännu inte kunnat hämtas från SPAR",
  MATCHED_WITH_SPAR = "Information om depån kunde automatiskt hämtas från SPAR",
  DELISTED_WITH_SPAR = "Av någon anledning så finns inte längre någon SPAR-koppling för depån. Personen kan ha flyttat utomlands.",
  MARKED_AS_DECEASED_BY_USER = "För att avsluta depån måste samtliga bevakningar raderas!",
  MARKED_AS_DECEASED_BY_SPAR = "För att avsluta depån måste samtliga bevakningar raderas!",
  DECEASED_WORK_IN_PROGRESS = "Lämna även ut anteckningar om depån ska arkiveras",
  ARCHIVED = "Ett år från och med arkiveringen så kommer depån att anonymiseras",
  UNEXPECTED = "Något gick fel när information hämtades från SPAR. Vänligen kontakta SBF:s support",
  DELETED = "Depån har raderats"
}

export enum StatusColor {
  ADDED = "grey lighten-2",
  MATCHED_WITH_SPAR = "grey lighten-2",
  DELISTED_WITH_SPAR = "grey lighten-2",
  MARKED_AS_DECEASED_BY_USER = "#f0ad4e",
  MARKED_AS_DECEASED_BY_SPAR = "#d9534f",
  DECEASED_WORK_IN_PROGRESS = "#5cb85c",
  ARCHIVED = "grey lighten-2",
  UNEXPECTED = "error",
  DELETED = "grey lighten-2",
  DECEASED_PROTECTED_IDENTITY = "#8a4220",
  DECEASED_WORK_IN_PROGRESS_PROTECTED_IDENTITY = "#8a4220"
}

export const StatusArr = [
  Status.ADDED,
  Status.MATCHED_WITH_SPAR,
  Status.DELISTED_WITH_SPAR,
  Status.MARKED_AS_DECEASED_BY_USER,
  Status.MARKED_AS_DECEASED_BY_SPAR,
  Status.ARCHIVED,
  Status.UNEXPECTED,
  Status.DECEASED_PROTECTED_IDENTITY,
  Status.DECEASED_WORK_IN_PROGRESS,
  Status.DECEASED_WORK_IN_PROGRESS_PROTECTED_IDENTITY,
  Status.DELETED
] as Array<Status>

export interface ProtectedIdentityResponse {
  protectedIdentity: boolean
}
