









































































































import Vue from 'vue';
import { Keepsake } from '@/interfaces/keepsake/keepsake';
import { ENDPOINTS, put } from '@/api/mainApi';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { DepotKeepsake } from '@/interfaces/keepsake/depotKeepsake';
import { Agreement, AgreementType, AgreementTypeValue } from '@/interfaces/depot/agreement';
import { Dialog } from '@/interfaces/presentation/dialog';
import { SigningInProgressState } from '@/interfaces/bankid/signingInProgressState';
import { pidPattern } from '@/validators';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import validator from 'validator';
import { BankidSignatureType } from '@/interfaces/bankid/bankidSignatureType';
import matches = validator.matches;
import { getParamValue } from '@/utils/generalUtil';
import { Business } from '@/interfaces/business/business';
import { hasOngoingAgreementSignatureAction } from '@/helpers/signatureHelper';
import { AgreementRepository } from '@/repositories/agreementRepository';

export default Vue.extend({
  components: {},
  props: {
    value: Boolean,
    keepsakes: Array as () => Array<Keepsake>,
    depotId: Number,
    otherBusiness: Object as () => Business | null,
    signingSourceId: String,
  },
  data() {
    return {
      tab: 0,
      sharedKeepsakes: [] as Array<Keepsake>,
      agreementRepository: new AgreementRepository(this),
      isUnsignedWarningShown: false,
      isUnsignedOverridden: false,
    };
  },
  computed: {
    valid() {
      return this.extraditableDepotKeepsakes.length > 0;
    },
    active: {
      get(): Boolean {
        return this.value;
      },
      set(value: Boolean) {
        this.$emit('update:value', value);
      },
    },
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress;
    },
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount;
    },
    usesBankId() {
      let soc = this.stateUser.loginCredentials.socialSecurityNumber;
      return soc != null && matches(soc, pidPattern);
    },
    ongoingExtraditionSigningInProgress(): boolean {
      return hasOngoingAgreementSignatureAction(this.signingInProgress, BankidSignatureType.EXTRADITION, this.signingSourceId, AgreementType.KEEPSAKE_EXTRADITION_AGREEMENT);
    },
    extraditableDepotKeepsakes(): Array<DepotKeepsake> {

      let depotKeepsakesToSend: Array<DepotKeepsake> = [];

      this.keepsakes.forEach(k => {

        let sharedKeepsake: Keepsake = this.sharedKeepsakes.find(sk => sk.id === k.id);

        if (sharedKeepsake) {
          sharedKeepsake.depotKeepsakes.forEach(dk => {
            if (dk.active) {
              depotKeepsakesToSend.push(dk);
            }
          });
        } else {
          k.depotKeepsakes.forEach(dk => {
            depotKeepsakesToSend.push(dk);
          });
        }
      });

      return depotKeepsakesToSend;
    },
  },
  watch: {
    active: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          if (this.sharedKeepsakes.length === 0) {
            this.active = false;
            this.confirmExtradition();
          }
        }
      },
    },
    sharedKeepsakes: {
      immediate: true,
      handler(value: Array<Keepsake>) {
        if (this.active && value.length === 0) {
          this.active = false;
          this.confirmExtradition();
        } else {
          this.setUnsignedStatuses(value);
        }
      },
    },
    keepsakes: {
      immediate: true,
      handler(keepsakes: Array<Keepsake>) {

        this.sharedKeepsakes = [];

        let first = true;

        if (keepsakes) {

          for (let keepsake of keepsakes) {

            // keepsake was shared but not extradited!
            if (keepsake.depotKeepsakes.length > 1 && !keepsake.depotKeepsakes.some(dk => dk.extradition)) {

              if (first) {
                keepsake.active = true;
                first = false;
              }
              this.sharedKeepsakes.push(keepsake);
            }
          }
        }
      },
    },
  },
  mounted() {
    if (this.ongoingExtraditionSigningInProgress) {
      this.extraditeKeepsakes(this.signingInProgress.depotKeepsakeIds, this.getBankIdSignatureIdParam());
    }
  },
  methods: {
    closeDialog() {
      this.active = false;
    },
    forceRerender() {
      this.$forceUpdate();
    },
    validateFinalExtraditionSize(keepsakesDepotsIds: string) {
      if (!keepsakesDepotsIds.length) {
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Finns inget att lämna ut. Vänligen välj en eller flera bevakningar',
          timeout: 30000,
          action: null,
        };

        this.$store.commit('setSnackbar', snackbar);
        return false;
      }

      return true;
    },
    extraditeKeepsakes(depotKeepsakeIds: string, bankidSignatureId: string) {

      let bankIdSignatureParam = '';
      if (bankidSignatureId) {
        bankIdSignatureParam += '?signatureId=' + bankidSignatureId;
      }

      put(ENDPOINTS.KEEPSAKES + '/' + depotKeepsakeIds + '/' + ENDPOINTS.EXTRADITE + bankIdSignatureParam, {})
        .then((agreements: Array<Agreement>) => {

          let snackbar: Snackbar = {
            active: true,
            color: 'success',
            text: 'Lyckades med utlämning av Bevakningar',
            timeout: 30000,
            action: null,
          };

          this.$store.commit('setSnackbar', snackbar);
          this.$emit('extradited', agreements);
          this.closeDialog();

        }).catch(err => {

        let errorMessage = err.data ? err.data.msg : '';
        if (errorMessage === 'Signed depot agreement required') {
          errorMessage = ' Påskrivet depåavtal saknas för en depå (troligtvis en delad)';
        }

        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Misslyckades med utlämning: ' + errorMessage,
          timeout: 30000,
          action: null,
        };

        this.$store.commit('setSnackbar', snackbar);
      }).finally(() => {
        // remove any signings in progress to be safe, and remove signing query params
        this.$store.commit('removeSigningInProgress');
      });
    },
    handleUnsignedOnConfirmed(overrideUnsignedWarning: boolean = false): boolean { // for shared keepsakes users can override the warning if they want to proceed
      if (overrideUnsignedWarning) {
        this.isUnsignedWarningShown = false;
      } else {
        console.log(this.extraditableDepotKeepsakes)
        let unsignedDepotKeepsakes: Array<DepotKeepsake> = this.extraditableDepotKeepsakes.filter(dk => dk.hasUnsignedDepotAgreement);
        if (unsignedDepotKeepsakes.length > 0) {
          this.isUnsignedWarningShown = true;
          return false;
        }
      }
      return true;
    },
    confirmExtradition(overrideUnsignedWarning: boolean = false) {
      if (!this.handleUnsignedOnConfirmed(overrideUnsignedWarning)) {
        return false;
      }
      let depotKeepsakesToExtradite: Array<DepotKeepsake> = this.extraditableDepotKeepsakes;
      let depotKeepsakeIds: string = depotKeepsakesToExtradite.map(k => k.id).join(',');

      const validFinalExtraditionSize: boolean = this.validateFinalExtraditionSize(depotKeepsakeIds);

      if (!validFinalExtraditionSize) {
        return false;
      }

      if (this.usesBankId) {
        // this delays the actual extradition since we shall navigate away
        this.setSigningStatusInStore(depotKeepsakeIds);
      }

      const additionalText = !!this.otherBusiness ? `Bevakningarna tillhör ${this.otherBusiness.name}. Kontakta kansliet för överflyttning.` : `Nya flikar med dessa avtal kommer öppnas i webbläsaren.`;
      this.active = false;
      let dialog: Dialog = {
        active: true,
        actionClick: () => {
          this.extraditeKeepsakes(depotKeepsakeIds);
        },
        title: 'Bekräfta utlämning',
        text: 'Är du helt säker på att du vill lämna ut valda bevakningar?' + '\n\n' +
          'Detta är en permanent ändring och bevakningsinformationen kommer att raderas efter ett visst tidsspann' + '\n\n' +
          'Utlämningsavtal kommer att skapas. ' + additionalText,
      };
      this.$store.commit('setCredentialsConfirmDialog', dialog);
    },
    removeSigning() {
      this.$store.commit('removeSigningInProgress');
    },
    setSigningStatusInStore(depotKeepsakeIds: string) {
      // prepare the signing by adding data for it, but it is not yet ready
      let signingInProgressState: SigningInProgressState = {
        sourceId: this.signingSourceId,
        bankidSignatureType: BankidSignatureType.EXTRADITION,
        depotId: this.depotId,
        depotKeepsakeIds: depotKeepsakeIds,
        agreementType: AgreementType.KEEPSAKE_EXTRADITION_AGREEMENT,
        ready: false,
      };

      this.$store.commit('setSigningInProgress', signingInProgressState);
    },
    getBankIdSignatureIdParam(): string | null {
      let bankIdSignatureId = getParamValue('signatureId');
      if (!bankIdSignatureId) {
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Kunde inte hitta BankID-signaturen för utlämning',
          timeout: 30000,
          action: null,
        };

        this.$store.commit('setSnackbar', snackbar);
      }
      return bankIdSignatureId;
    },
    async hasUnsignedDepotAgreement(depotKeepsake: DepotKeepsake): Promise<boolean> {
      let hasSignedDepotAgreement = await this.agreementRepository.depotHasDepotAgreementOfType(depotKeepsake.depot.id, AgreementTypeValue.DEPOT_AGREEMENT, true);
      return !hasSignedDepotAgreement;
    },
    async setUnsignedStatuses(value: Keepsake[]) {
      for (let i = 0; i < value.length; i++) {
        let keepsake: Keepsake = value[i];
        for (let j = 0; j < keepsake.depotKeepsakes.length; j++) {
          let dk: DepotKeepsake = keepsake.depotKeepsakes[j];
          if (!dk.hasUnsignedDepotAgreement) {
            dk.hasUnsignedDepotAgreement = await this.hasUnsignedDepotAgreement(dk);
          }
        }
      }
    },
    selectAll() {
      for (let i = 0; i < this.sharedKeepsakes.length; i++) {
        let keepsake: Keepsake = this.sharedKeepsakes[i];
        for (let j = 0; j < keepsake.depotKeepsakes.length; j++) {
          let dk: DepotKeepsake = keepsake.depotKeepsakes[j];
          dk.active = true;
        }
      }
      this.forceRerender();
    },
  },
});

