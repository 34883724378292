import { ENDPOINTS, get, getService, MainApi } from '@/api/mainApi';
import Vue from 'vue';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { AxiosError } from 'axios';
import { ContactInformation } from '@/interfaces/contactInformation';

export class SparRepository {
  constructor(private vueInstance: Vue) {
  }

  public async getNameFromSSN(ssn: string): Promise<string> {
      try {
        return await getService(`${ENDPOINTS.BISNODE}/${ENDPOINTS.SPAR}/${ENDPOINTS.SEARCH}/${ssn}/${ENDPOINTS.NAME}`)
      } catch (error) {
        this.handleError(error, `Misslyckades med att hämta information tillhörande personnummer ${ssn}: ${error.data.msg || 'Okänt fel'}`)
        return Promise.reject(error)
      }
  }

  public async getContactInformation(ssn: string): Promise<ContactInformation> {
    try {
      return await getService(`${ENDPOINTS.BISNODE}/${ENDPOINTS.SPAR}/${ENDPOINTS.SEARCH}/${ssn}/${ENDPOINTS.CONTACTINFORMATION}`)
    } catch (error) {
      this.handleError(error, `Misslyckades hämta kontaktuppgifter: ${error.data.msg || 'Okänt fel'}`)
      return Promise.reject(error)
    }
  }

  private setSnackbar(message: string, color: 'error' | 'success') {
    const snackbar: Snackbar = {
      active: true,
      color: color,
      text: message,
      timeout: 10000,
      action: null,
    };
    this.vueInstance.$store.commit('setSnackbar', snackbar);
  }

  private handleError(error: AxiosError, errorMessage: string) {
    if (error.response?.status === 401) {
      this.setSnackbar('Du saknar rättigheter för att utföra den begärda handlingen', 'error');
    } else {
      this.setSnackbar(errorMessage, 'error');
    }
  }
}