<template>
  <v-dialog
    v-model="dialog"
    max-width="450px"
    persistent
  >
    <v-card>
      <v-card-title class="headline secondary--text">
        Ej sparade ändringar
        <v-spacer />
        <v-icon
          color="secondary"
          right
        >
          mdi-content-save-alert-outline
        </v-icon>
      </v-card-title>
      <v-card-text class="mt-2">
        <p class="body-1">
          Du har osparade ändringar.
        </p>
        <p class="mt-1">
          Är du säker på att du vill navigera bort från denna vy?
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="cancel"
        >
          Avbryt
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="continueNav"
        >
          Fortsätt
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    interruptedTo: null,
    dialog: false
  }),
  computed: {
    unsavedChanges: {
      get: function ()
      {
        return this.value;
      },
      set: function (newValue)
      {
        this.$emit('update:value', newValue)
      }
    },
  },
  beforeMount()
  {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () =>
    {
      window.removeEventListener("beforeunload", this.preventNav)
    })
  },
  methods: {
    hasUnsavedChanges()
    {
      if (this.unsavedChanges && !this.disabled)
      {
        this.dialog = true
        return true
      }
      return false
    },
    preventNav(event)
    {
      if (!this.unsavedChanges || this.disabled)
      {
        return
      }
      event.preventDefault()
      event.returnValue = ""
    },
    beforeRouteLeave(to, from, next)
    {
      this.interruptedTo = to
      if (!this.hasUnsavedChanges() || this.disabled)
      {
        next();
      }
    },
    cancel() {
      this.$emit('cancel')
      this.dialog = false
    },
    continueNav() {
      this.$emit('continue')
      this.unsavedChanges = false
      this.$nextTick(() => this.$router.push(this.interruptedTo))
    }
  },
}
</script>
