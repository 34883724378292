



















































































import Vue from 'vue';
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {Role} from "@/enums/role";
import OrganizationAdminComponent from "@/components/administration/OrganizationAdminComponent.vue";
import {Organization} from "@/interfaces/organization/organization";
import {Business} from "@/interfaces/business/business";
import BusinessAdminComponent from "@/components/administration/BusinessAdminComponent.vue";
import UserAccountAdminComponent from "@/components/administration/UserAccountAdminComponent.vue";
import PlacesAdminComponent from "@/components/administration/PlacesAdminComponent.vue";
import ExternalAgentAdminComponent from "@/components/administration/ExternalAgentAdminComponent.vue";
import ErrorMessageComponent from "@/components/ErrorMessageComponent.vue";
import {ENDPOINTS, get} from "@/api/mainApi";
import ExtendedOrganizationAdminComponent from "@/components/administration/ExtendedOrganizationAdminComponent.vue";
import {OrganizationSettings} from "@/interfaces/organization/organizationSettings";
import {Office} from "@/interfaces/office/office";
import {isBusinessAdmin, isBusinessUser, isOrgAdmin, isSysAdmin} from "@/helpers/userAccountHelper";
import BusinessMergeHistory from '@/components/administration/BusinessMergeHistory.vue';

export default Vue.extend({
  components: {
    BusinessMergeHistory,
    ExternalAgentAdminComponent,
    OrganizationAdminComponent,
    BusinessAdminComponent,
    UserAccountAdminComponent,
    PlacesAdminComponent,
    ErrorMessageComponent,
    ExtendedOrganizationAdminComponent
  },
  data() {
    return {
      selectedOrganization: null as null | Organization,
      selectedBusiness: null as null | Business,
      autoAddedStoragePlaceState: false as Boolean
    }
  },
  computed: {
    user(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    columnSize() {
      // to make sure layout is responsive even if there are fewer components it needs to react to
      let columns = 4
      if (this.isOrgAdmin || this.isSysAdmin) {
        columns = 6
      } else if (this.isBusinessAdmin) {
        columns = 12
      }
      return columns
    },
    isSysAdmin(): boolean {
      return isSysAdmin(this.user);
    },
    isOrgAdmin(): boolean {
      return isOrgAdmin(this.user);
    },
    isBusinessAdmin(): boolean {
      return isBusinessAdmin(this.user);
    },
    isBusinessUser(): boolean {
      return isBusinessUser(this.user)
    },
  },
  watch: {
    selectedOrganization(value) {
      if (value) {
        // reset the business if the org is changed
        this.selectedBusiness = null;
        this.setOrganizationSettings(value)
      }
    }
  },
  async mounted() {
    await this.setInitialBusiness();
  },
  methods: {
    async setInitialBusiness(){
      if (this.user.office || this.user.business) {
        const businessId = this.user.office ? this.user.office.business.id : this.user.business.id;

        let response = await get(ENDPOINTS.BUSINESSES + "/" + businessId);

        if (response) {
          let business: Business = response;

          this.selectedOrganization = business.organization;

          this.$nextTick(() => {
            this.selectedBusiness = business;
          })
        }
      }
    },
    selectBusiness(business: Business) {
      this.selectedBusiness = business
    },
    async setOrganizationSettings(organization: Organization) {
      let organizationSetting: OrganizationSettings = await this.getOrganizationSettings(organization);
      this.$store.commit('updateOrganizationSettings', organizationSetting)
    },
    async getOrganizationSettings(organization: Organization) {
      let response = await get(ENDPOINTS.ORGANIZATION_SETTINGS, {organizationId: organization.id});
      if (response) {
        return response;
      }

      return null;
    },
    autoAddedStoragePlace(office: Office) {
      this.autoAddedStoragePlaceState = true;

      setTimeout(() => {
        this.autoAddedStoragePlaceState = false;
      })
    },
  }
})
