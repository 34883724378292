

































































import { BusinessMergerReceipt } from '@/interfaces/business/mergerReceipt';
import { Dialog } from '@/interfaces/presentation/dialog';
import { BusinessMergerRepository } from '@/repositories/businessMergerRepository';
import dayjs from 'dayjs';
import Vue from 'vue';

export default Vue.extend({
    components: {},
    props: {
        businessMergerReceipt: Object as () => BusinessMergerReceipt
    },
    data() {
        return {
            loadingRevert: false,
            mergeRepository: new BusinessMergerRepository(this),
        }
    },
    computed: {
        dayOfMove(): string {
          return dayjs(this.businessMergerReceipt.createdAt).format("YYYY-MM-DD")
        },
        isExpired(): boolean {
          return this.businessMergerReceipt.expired
        },
        isReverted(): boolean {
          return this.businessMergerReceipt.reverted
        },
        isPossibleToRevert(): boolean {
            return !this.isReverted && !this.isExpired
        },
    },
    methods: {
        
        confirmMergerRevert() {
            let dialog: Dialog = {
                active: true,
                actionBtnText: "Återställ",
                title: "Ångra flytt",
                text: `Är du säker på att du vill ångra flytten?
                    Denna åtgärd kommer försöka återställa flytten från '${this.businessMergerReceipt.oldBusiness.name}' till '${this.businessMergerReceipt.newBusiness.name}'.
                    Total kommmer:
                    ${this.businessMergerReceipt.agreementIds.length} avtal,
                    ${this.businessMergerReceipt.agreementSignatureIds.length} avtals signaturerer,
                    ${ this.businessMergerReceipt.tosAgreementIds.length } användaravtal,
                    ${ this.businessMergerReceipt.userAccountIds.length } användare,
                    ${ this.businessMergerReceipt.officeIds.length } kontor
                    flyttas tillbaka till ${this.businessMergerReceipt.oldBusiness.name}
                `,
                actionClick: () => {
                    this.loadingRevert = true;
                    this.mergeRepository.undoMerger(this.businessMergerReceipt.id)
                        .finally(() => {
                            // once completed - successfully or not
                            // reload data
                            this.$emit('reverted')                    
                            this.loadingRevert = false;
                        })
                },
                closeBtnText: "Stäng"
            }
            this.$store.commit('setDialog', dialog)
        },
    }
})
