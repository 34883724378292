

























































import Vue from 'vue';
  
export default Vue.extend({
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    titleText: {
      type: String,
      default: "",
    },
    titleIcon: {
      type: String || null,
      default: null,
    },
    textMain: {
      type: String,
      default: "",
    },
    footerInfo: {
      type: Object as () => Record<string, string> || null,
      default: null,
    },
    actionInfo: {
      type: String || null,
      default: null,
    }
  },
  data() {
    return {
      localTextMain: this.textMain,
    }
  },
  watch: {
    textMain(val) {
      this.localTextMain = val
    },
  },
});
