


















































































































































































































































import Vue from 'vue';
import {Business} from "@/interfaces/business/business";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Dialog} from "@/interfaces/presentation/dialog";
import {StoragePlace} from "@/interfaces/place/storagePlace";
import {deleteRequest, ENDPOINTS, get, post, put} from "@/api/mainApi";
import PlaceTransferDialogComponent from "@/components/place/PlaceTransferDialogComponent.vue";

export default Vue.extend({
  components: {PlaceTransferDialogComponent},
  props: {
    business: Object as () => Business,
    autoAddedStoragePlaceState: Boolean
  },
  data() {
    return {
      places: [] as StoragePlace[],
      selected: null as StoragePlace | null,
      loading: false,
      newPlace: {
        name: "",
        description: "",
        business: null
      } as StoragePlace | null,
      newDialog: false,
      fromPlace: null,
      toPlace: null,
      moveKeepsakesDialog: false,
      loadingKeepsakeMove: false,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      newFormValid: false,
      moveFormValid: false,
      selectedFormValid: false,
      selectedFormChanged: false,
    }
  },
  computed: {
    fromPlaces() {
      // Only places that have not been selected as a toPlace can be selected
      if (this.toPlace == null) {
        return this.places
      }
      return this.places.filter((place: StoragePlace) => place.id != this.toPlace.id)
    },
    toPlaces() {
      if (this.fromPlace == null) {
        return this.places
      }
      // Only places that have not been selected as a fromPlace can be selected
      return this.places.filter((place: StoragePlace) => place.id != this.fromPlace.id)
    },
    businessName(): string {
      return this.selectedBusiness ? this.selectedBusiness.name : ''
    },
    selectedBusiness: {
      get(): Business {
        return this.business
      },
      set(val: Business) {
        this.$emit('update:business', val)
      }
    }
  },
  watch: {
    selectedBusiness(val: Business) {

      this.places = [];
      this.selected = null;

      if (val && val.id) {
        this.newPlace.business = val;
        this.getStoragePlaces(val.id);
      }
    },
    autoAddedStoragePlaceState(val: StoragePlace) {
      if (val) {
        this.getStoragePlaces(this.selectedBusiness.id)
      }
    }
  },
  methods: {
    selectPlace(place) {
      const selectedPlace = {...place};
      this.selected = selectedPlace;
      this.$emit('select', selectedPlace);
      this.places.forEach(o => o.active = false);
      selectedPlace.active = true;
      this.selectedFormChanged = false;
    },
    async getStoragePlaces(businessId) {
      this.loading = true
      get(ENDPOINTS.BUSINESSES + "/" + businessId + "/" + ENDPOINTS.STORAGE_PLACES)
        .then((res: Array<StoragePlace>) => {
          this.places = res;
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false;
        })
    },
    async moveKeepsakes() {

      this.$refs.moveForm.validate();

      if (this.moveFormValid) {

        if (this.fromPlace && this.toPlace) {

          this.loadingKeepsakeMove = true

          put(ENDPOINTS.STORAGE_PLACES + "/" + this.fromPlace.id + "/" + ENDPOINTS.KEEPSAKES + "/" + ENDPOINTS.MOVE + "?toStoragePlaceId=" + this.toPlace.id, {})
            .then((res) => {

              this.fromPlace.keepsakeCount = 0;
              this.toPlace.keepsakeCount += res;

              let snackbar: Snackbar = {
                active: true,
                color: "success",
                text: "Flyttade " + res + " bevakningar från " + this.fromPlace.name + " till " + this.toPlace.name,
                timeout: 8000,
                action: null
              };
              this.$store.commit('setSnackbar', snackbar);
            })
            .catch((err) => {
              console.log(err);

              let snackbar: Snackbar = {
                active: true,
                color: "error",
                text: "Misslyckades med att flyta bevakningar: " + err.data.msg,
                timeout: 8000,
                action: null
              };
              this.$store.commit('setSnackbar', snackbar);

            }).finally(() => {
            this.loadingKeepsakeMove = false;
            this.moveKeepsakesDialog = false;

            this.fromPlace = null;
            this.toPlace = null;
          })
        } else {
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Du måste välja både till och från",
            timeout: 8000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        }
      }
    },
    updatePlace(place: StoragePlace) {

      let valid = this.$refs.selectedform.validate()

      if (valid) {

        this.loading = true;
        put(ENDPOINTS.STORAGE_PLACES + "/" + place.id, place)
          .then(res => {
            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Uppdaterade " + place.name,
              timeout: 8000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
            this.selectedFormChanged = false;
            const updatedPlace = this.places.find((foundPlace: StoragePlace) => foundPlace.id === place.id);
            if (!updatedPlace) {
              return;
            }
            updatedPlace.name = place.name;
            updatedPlace.description = place.description;
          })
          .catch(err => {
            console.log(err);
            let snackbar: Snackbar = {
              active: true,
              color: "error",
              text: "Misslyckades med att uppdatera förvaringsplatsen: " + err.data.msg,
              timeout: 10000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar)
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    confirmDeletePlace(place: StoragePlace) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Radera förvaringsplats",
        title: "Bekräfta radering",
        text: `Är du säker på att du vill radera ${this.selected.name}?`,
        actionClick: () => {
          this.deletePlace(place)
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    async deletePlace(place: StoragePlace) {

      this.loading = true;

      try {
        let deleted = await deleteRequest(ENDPOINTS.STORAGE_PLACES + "/" + place.id);

        if (deleted) {

          let index = this.places.findIndex((v: StoragePlace) => v.id == place.id)
          if (index !== -1) {
            this.places.splice(index, 1);
            this.selected = null;

            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Raderade förvaringsplatsen",
              timeout: 6000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar)
          }
        }
      } catch (err) {
        console.log(err);
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Misslyckades med att radera förvaringsplatsen: " + err.data.msg,
          timeout: 10000,
          action: null
        }
        this.$store.commit('setSnackbar', snackbar)
      } finally {
        this.loading = false;
      }
    },
    addPlace() {

      this.$refs.newForm.validate();

      if (this.newFormValid) {

        this.loading = true;

        post(ENDPOINTS.STORAGE_PLACES, this.newPlace)
          .then((place: StoragePlace) => {
            this.places.push(place);

            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "La till " + this.newPlace.name,
              timeout: 8000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar);

            this.newPlace = {
              name: "",
              description: "",
              business: this.selectedBusiness
            } as StoragePlace

          }).catch(err => {
          console.log(err.data.msg);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att lägga till förvaringsplats: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        }).finally(() => {
          this.loading = false;
          this.newDialog = false
        })
      }
    },
    handleKeepsakeCountModification(event) {

      let fromPlace: StoragePlace = event.fromPlace;
      let toPlace: StoragePlace = event.toPlace;

      this.places.find(x => x.id === fromPlace.id).keepsakeCount = fromPlace.keepsakeCount;
      this.places.find(x => x.id === toPlace.id).keepsakeCount = toPlace.keepsakeCount;
    }
  }
})
