
































































































































































import Vue from 'vue';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { Role, RoleArray } from '@/enums/role';
import { Dialog } from '@/interfaces/presentation/dialog';
import { ENDPOINTS, post, put } from '@/api/mainApi';
import { ActivationStatusTypeText } from '@/enums/activationStatusType';
import UserAccountFormComponent from '@/components/useraccount/UserAccountFormComponent.vue';
import selectableRoles from '@/helpers/roleHelper';
import { isAdmin } from '@/helpers/userAccountHelper';

export default Vue.extend({
  components: { UserAccountFormComponent },
  props: {
    value: Object as () => UserAccount,
    allowDelete: Boolean,
    allowRoleChange: Boolean,
    allowResendInvitation: Boolean,
    flat: Boolean,
    readOnlySocialSecurityNumber: Boolean,
  },
  data() {
    return {
      roles: RoleArray,
      loading: false,
      newApiKeyDialog: false,
      newApiKey: '',
      emailRules: [
        v => /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig',
      ],
      requiredRules: [
        v => !!v || 'Obligatoriskt fält',
      ],
      userFormValid: false,
    };
  },
  computed: {
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount;
    },
    user: {
      get: function(): UserAccount {
        return this.value;
      },
      set: function(newValue: UserAccount) {
        this.$emit('update:value', newValue);
      },
    },
  },
  methods: {
    getSelectableRoles() {
      return selectableRoles(this.stateUser, this.roles);
    },
    async updateUser(user: UserAccount) {

      let valid = this.$refs.userForm.validate();

      if (user && valid) {

        this.loading = true;

        try {
          let updateResponse = await put(ENDPOINTS.USER_ACCOUNTS + '/' + user.id, user);

          if (updateResponse) {
            let snackbar: Snackbar = {
              active: true,
              color: 'success',
              text: 'Sparade ändringar för ' + this.user.firstname,
              timeout: 6000,
              action: null,
            };
            this.$store.commit('setSnackbar', snackbar);
          }
        } catch (err) {
          console.log(err);
          let snackbar: Snackbar = {
            active: true,
            color: 'error',
            text: 'Misslyckades med att spara ändringarna: ' + err.data.msg,
            timeout: 10000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);
        } finally {
          this.loading = false;
        }
      }
    },
    confirmDeleteUser(user: UserAccount) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: 'Inaktivera användare',
        title: 'Bekräfta inaktivering',
        text: 'Är du säker på att du vill inaktivera ' + this.user.firstname + '?',
        actionClick: () => {
          this.deleteUser(user);
        },
        closeBtnText: 'Stäng',
      };
      this.$store.commit('setDialog', dialog);
    },
    confirmSendInvitation(user: UserAccount) {
      if (this.$refs.userForm.validate()) { // to make sure a valid email has been provided
        let dialog: Dialog = {
          active: true,
          actionBtnText: 'Skicka aktiveringslänk',
          title: 'Bekräfta utskick av aktiveringslänk',
          text: 'Är du säker på att du vill skicka ut en aktiveringslänk till ' + this.user.firstname + ' (' + this.user.email + ')?',
          actionClick: () => {
            this.sendActivationInvitation(user);
          },
          closeBtnText: 'Stäng',
        };
        this.$store.commit('setDialog', dialog);
      }
    },
    deleteUser(user: UserAccount) {
      this.$emit('delete', user);
    },
    sendActivationInvitation(user: UserAccount) {
      this.loading = true;
      post(ENDPOINTS.USER_ACCOUNTS + '/' + user.id + '/' + ENDPOINTS.INVITE)
        .then(response => {
          let snackbar: Snackbar = {
            active: true,
            color: 'success',
            text: response.msg,
            timeout: 8000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);

        }).catch(err => {
        console.error(err);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Misslyckades med att skicka aktiveringsmejl: ' + err.data,
          timeout: 10000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      }).finally(() => {
        this.loading = false;
      });
    },
    confirmGenerateNewApiKey(user: UserAccount) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: 'Generera tredjepartsnyckel',
        title: 'Bekräfta generering av ny tredjepartsnyckel',
        text: 'Är du säker på att du vill generera en ny tredjepartsnyckel till ditt användarkonto?',
        actionClick: () => {
          this.generateNewApiKey(user);
        },
        closeBtnText: 'Stäng',
      };
      this.$store.commit('setDialog', dialog);
    },
    isAnyLevelAdmin: isAdmin,
    allowedToModify(otherUser: UserAccount) {
      if (otherUser.id === this.stateUser.id) {
        return isAdmin(this.stateUser);
      }

      let ownRole: Role = this.stateUser.role;
      let ownRoleIndex: number = this.roles.findIndex((role) => role.value === ownRole);
      let otherRole: Role = otherUser.role;
      let otherRoleIndex: number = this.roles.findIndex((role) => role.value === otherRole);

      return ownRoleIndex <= otherRoleIndex;
    },
    isActivated(account: UserAccount) {
      return account.activationStatus && account.activationStatus.activationStatusType === ActivationStatusTypeText.ACTIVATED;
    },
    hasUserName(account: UserAccount) {
      return account.loginCredentials && account.loginCredentials.username && account.loginCredentials.username.length > 0;
    },
    async generateNewApiKey(user: UserAccount) {
      try {
        let apiKey = await put(ENDPOINTS.USER_ACCOUNTS + '/' + user.id + '/' + ENDPOINTS.API_KEY, {});
        if (apiKey) {
          user.apiKey = apiKey;
          this.newApiKey = apiKey;
          this.newApiKeyDialog = true;
        }
      } catch (e) {
        console.error(e);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Något gick fel när api-nyckeln skulle skapas',
          timeout: 8000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      }
    },
  },
});
