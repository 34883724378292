import { render, staticRenderFns } from "./DocumentStatisticsSummary.vue?vue&type=template&id=750cfdd8&scoped=true&"
import script from "./DocumentStatisticsSummary.vue?vue&type=script&lang=ts&"
export * from "./DocumentStatisticsSummary.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentStatisticsSummary.vue?vue&type=style&index=0&id=750cfdd8&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "750cfdd8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCard,VCardSubtitle,VCardText,VCardTitle,VDivider,VSelect})
