






































































































import Vue from 'vue';
import {Depot} from "@/interfaces/depot/depot";
import {pidPattern} from "@/validators";
import matches from "validator/lib/matches";
import KeepsakeDepotSelectComponent from "@/components/keepsake/KeepsakeDepotSelectComponent.vue";
import NewKeepsakesComponent from "@/components/keepsake/NewKeepsakesComponent.vue";
import UnsavedChangesDialogComponent from "@/components/dialog/UnsavedChangesDialogComponent.vue";
import {ENDPOINTS, get, post} from "@/api/mainApi";
import {Keepsake} from "@/interfaces/keepsake/keepsake";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {DepotKeepsake} from "@/interfaces/keepsake/depotKeepsake";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Dialog} from "@/interfaces/presentation/dialog";
import {SigningInProgressState} from "@/interfaces/bankid/signingInProgressState";
import {BankidSignatureType} from "@/interfaces/bankid/bankidSignatureType";
import {NewKeepsakesSignatureData} from "@/interfaces/bankid/newKeepsakesSignatureData";
import {getParamValue} from "@/utils/generalUtil";
import ErrorMessageComponent from "@/components/ErrorMessageComponent.vue";
import { hasOngoingSignatureAction } from '@/helpers/signatureHelper';

export default Vue.extend({
  components: {KeepsakeDepotSelectComponent, NewKeepsakesComponent, UnsavedChangesDialogComponent, ErrorMessageComponent},
  data() {
    return {
      depot1: {
        socialSecurityNumber: "",
        name: "",
        createdBy: null,
      } as Depot,
      depot2: null as Depot | null,
      loading: false,
      unsavedChanges: false,
      saving: false,
      shared: false,
      rules: {},
      keepsakes: [] as Array<Keepsake>,
      depotSelectValid: false,
      disabledUnsavedChanges: false,
      errorMsg: "",
      signingSourceId: "c59dbdae-1b4d-4ec7-bd98-561ffb161b87",
    }
  },
  computed: {
    userHasOffice(): boolean{
      return this.user && this.user.office && this.user.office !== undefined;
    },
    depot1Changed() {
      return this.depot1.socialSecurityNumber;
    },
    selectableDepots() {
      let val = [this.depot1]
      if (this.depot2) {
        val = [this.depot1, this.depot2]
      }
      return val
    },
    user(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    activeCredentialsDialog (): boolean {
      return this.$store.state.credentialsConfirmDialog.active
    },
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress
    },
    ongoingCreatingNewKeepsakesSigningInProgress(): boolean {
      return hasOngoingSignatureAction(this.signingInProgress, BankidSignatureType.CREATING_NEW_KEEPSAKES, this.signingSourceId);
    },
  },
  watch: {
    depot1Changed() {
        this.unsavedChanges = true;
    },
    depot2() {
      this.unsavedChanges = true;
    },
    activeCredentialsDialog(val) {
      if (!val) {
        setTimeout(() => {
          this.disabledUnsavedChanges = false;
        }, 1200)
      }
    }
  },
  async mounted() {

    if (this.ongoingCreatingNewKeepsakesSigningInProgress) {
      const storedNewKeepsakesData: NewKeepsakesSignatureData = this.signingInProgress.newKeepsakesSignatureData;
      this.depot1 = storedNewKeepsakesData.depot1;
      this.depot2 = storedNewKeepsakesData.depot2;
      this.keepsakes = storedNewKeepsakesData.newKeepsakes;

      await this.createNewKeepsakes(this.getBankIdSignatureIdParam());
    }
    else {
      let depotId = this.$route.query.depotId
      let ssnQuery = this.$route.query.ssn
      if (depotId && depotId > 0) {
        await this.getDepotFromId(depotId)
      } else if (ssnQuery && matches(ssnQuery, pidPattern)) {
        // this will trigger watch in KeepsakeDepotSelectComponent (which will validate some stuff)
        this.depot1 = {
          socialSecurityNumber: ssnQuery
        }
      }
    }
  },
  beforeRouteLeave(to, from, next)
  {
    this.$refs.unsaved.beforeRouteLeave(to, from, next)
  },
  methods: {
    async getDepotFromId(id) {
      this.loading = true
      try {
        let response = await get(ENDPOINTS.DEPOTS + "/" + id, {reducedData: true})
        if(response && response.id)
        {
          this.depot1 = response
        }
      }
      catch (e) {
        console.error(e)
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Något gick fel när data om depån skulle hämtas. Säkerställ att depå-id är korrekt.",
          timeout: 8000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
        this.depot1 = {
          socialSecurityNumber: "",
          name: "",
          createdBy: null,
        }
      }
      this.loading = false
    },
    async submit() {
      // just some basic validation here
      if (this.depot1 && this.depot1.name.length > 0 && matches(this.depot1.socialSecurityNumber, pidPattern) && this.$refs.depotSelectForm.validate()) {
        if (this.validateDepotKeepsakes()) {
          this.disabledUnsavedChanges = true;
          this.setSigningStatusInStore()
          let dialog: Dialog = {
            active: true,
            actionClick: () => this.createNewKeepsakes(),
            title: "Slutför skapandet av nya bevakningar",
            text: "Är du helt säker på att du vill skapa nya bevakningar? Depåavtal kommer att skapas efter behov" + "\n\n",
          };

          setTimeout(() => {
            this.$store.commit('setCredentialsConfirmDialog', dialog);
          }, 200)
        }
      }
    },
    async createNewKeepsakes(bankidSignatureId: string) {

      this.saving = true

      let bankIdSignatureParam = bankidSignatureId ? "?signatureId=" + bankidSignatureId : ""

      // Start by creating the Depots if they don't have an id
      let snackbarMsg = "";
      try {
        snackbarMsg = await this.checkAndCreateDepots()
      } catch (error) {
        console.log(error);
        this.saving = false;
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: this.errorMsg,
          timeout: 15000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
        this.errorMsg = "";
        return false;
      }
      this.unsavedChanges = false;

      // then we build depotKeepsakes and post keepsakes to backend
      this.buildDepotKeepsakes()
      if (this.keepsakes.length > 0) {
        try {
          let cleanedKeepsakes = this.keepsakes;
          cleanedKeepsakes.forEach(ck => ck.depotKeepsakes.forEach(dkps => dkps.depot.createdAt = null));
          let response = await post(ENDPOINTS.DEPOTS + "/" + ENDPOINTS.KEEPSAKES + bankIdSignatureParam, {keepsakes: cleanedKeepsakes})
        } catch (e) {
          console.error(e)
          this.saving = false
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Något gick fel när bevakningarna skulle skapas. Vänligen kontakta support!",
            timeout: 15000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
          return
        }
        finally {
          this.disabledUnsavedChanges = false;
          this.removeSigning();
        }
        if (snackbarMsg.length > 0) {
          snackbarMsg += " och bevakningar"
        } else {
          snackbarMsg = "Bevakningar skapade"
        }
      }
      snackbarMsg += "!"
      // lastly navigate to first depot and show snackbar
      this.$router.push({name: 'depotDetails', params: {id: this.depot1.id}})
      let snackbar: Snackbar = {
        active: true,
        color: "success",
        text: snackbarMsg,
        timeout: 5000,
        action: null
      };
      this.$store.commit('setSnackbar', snackbar);
      this.saving = false
    },
    setSigningStatusInStore() {
      // prepare the signing by adding data for it, but it is not yet ready
      let signingInProgressState: SigningInProgressState = {
        bankidSignatureType: BankidSignatureType.CREATING_NEW_KEEPSAKES,
        sourceId: this.signingSourceId,
        newKeepsakesSignatureData: {
          depot1: this.depot1,
          depot2: this.depot2,
          newKeepsakes: this.keepsakes,
        } as NewKeepsakesSignatureData,
        ready: false
      }

      this.$store.commit('setSigningInProgress', signingInProgressState)
    },
    buildDepotKeepsakes() {
      this.keepsakes.forEach((keepsake: Keepsake) => {
        let depotKeepsakes = [] as Array<DepotKeepsake>;
        depotKeepsakes.push(this.newDepotKeepsake(keepsake.depots[0], keepsake))
        // if the keepsake is shared between depots we need to create two depotKeepsakes
        if (keepsake.depots.length > 1) {
          depotKeepsakes.push(this.newDepotKeepsake(keepsake.depots[1], keepsake))
        }
        keepsake.depotKeepsakes = depotKeepsakes
      })
    },
    newDepotKeepsake(depot: Depot, keepsake: Keepsake): DepotKeepsake {
      // this is needed otherwise we get circular dependency
      let keepsakeNoDepot: any = Object.assign({}, keepsake)
      keepsakeNoDepot.depots = null
      return {
        keepsake: keepsakeNoDepot,
        depot: depot,
        office: this.user.office,
        id: null,
        createdBy: this.user.id,
      } as DepotKeepsake
    },
    async checkAndCreateDepots() {
      // will create depots if they don't have an id. Returns snackbarMsg
      let snackbarMsg = ""
      if (!this.depot1.id) {
        snackbarMsg = "Skapade depå"
        this.depot1.createdBy = this.user.id

        try {
          this.depot1 = await post(ENDPOINTS.DEPOTS, this.depot1)
        } catch (error) {
          console.error(error);
          if (error.status === 409) {
            this.errorMsg = 'Det finns redan en depå med pers.nr:' + this.depot1.socialSecurityNumber;
          } else {
            this.errorMsg = 'Något gick fel: ' + error.data.msg;
          }
          throw this.errorMsg;
        }
        // Id change must be manually propagated to the keepsakes
        this.propagateDepotId(this.depot1)
      }
      if (this.depot2 && !this.depot2.id) {
        snackbarMsg = "Skapade depåer"
        this.depot2.createdBy = this.user.id;

        try {
          this.depot2 = await post(ENDPOINTS.DEPOTS, this.depot2)
        } catch (error) {
          console.error(error);
          if (error.status === 409) {
            this.errorMsg = 'Det finns redan en depå med pers.nr:' + this.depot2.socialSecurityNumber;
          } else {
            this.errorMsg = 'Något gick fel: ' + error.data.msg;
          }
          throw this.errorMsg;
        }
        // Id change must be manually propagated to the keepsakes
        this.propagateDepotId(this.depot2)
      }
      return snackbarMsg
    },
    validateDepotKeepsakes(): boolean {
      // check to make sure depot doesn't have an id if keepsakes are empty
      if (this.depot1.id && this.keepsakes.length == 0) {
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Det går inte att välja en redan skapad depå utan att skapa upp nya bevakningar!",
          timeout: 8000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
        return false
      }
      return true
    },
    ssnCleared() {
      this.keepsakes = [];
    },
    propagateDepotId(depot: Depot) {
      this.keepsakes.forEach((keepsake: Keepsake) => {
        let index = keepsake.depots.findIndex((kd) => kd.socialSecurityNumber === depot.socialSecurityNumber)
        if (index >= 0) {
          keepsake.depots[index].id = depot.id
        }
      })
    },
    removeSigning() {
      this.$store.commit('removeSigningInProgress')
    },
    getBankIdSignatureIdParam(): string | null {

      let bankIdSignatureId = getParamValue("signatureId");

      if (!bankIdSignatureId)
      {
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Kunde inte hitta BankID-signaturen inför skapandet av bevakningar",
          timeout: 30000,
          action: null
        };

        this.$store.commit('setSnackbar', snackbar);
      }
      return bankIdSignatureId;
    }
  }
})
