
























































































import Vue from 'vue';
import {Organization} from "@/interfaces/organization/organization";
import {ENDPOINTS, get} from "@/api/mainApi";
import {OrganizationRegistrationPage} from "@/interfaces/organization/organizationRegistrationPage";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import OrganizationRegistrationCreateComponent
  from "@/components/organization/OrganizationRegistrationCreateComponent.vue";
import OrganizationRegistrationEditComponent from "@/components/organization/OrganizationRegistrationEditComponent.vue";
import OrganizationAgreementSettingsComponent
  from "@/components/organization/OrganizationAgreementSettingsComponent.vue";
import OrganizationPlacesSettingsComponent from "@/components/organization/OrganizationPlacesSettingsComponent.vue";
import KeepsakeTypeAdminComponent from '@/components/administration/KeepsakeTypeAdminComponent.vue';

export default Vue.extend({
        components: {
          KeepsakeTypeAdminComponent,
          OrganizationPlacesSettingsComponent,
          OrganizationAgreementSettingsComponent,
          OrganizationRegistrationCreateComponent,
          OrganizationRegistrationEditComponent
        },
        props: {
          organization: Object as () => Organization,
          title: String,
          dialogMode: Boolean,
          showKeepsakeTypeAdmin: Boolean,
          showLinkRegistration: Boolean,
          showAgreementSettings: Boolean,
          showPlacesSettings: Boolean,
        },
        data() {
            return {
                organizationRegistrationPage: null as OrganizationRegistrationPage | null,
                newDialog: false,
                newFormValid: false,
                loading: false,
                selectedFormValid: false,
                requiredRules: [
                    v => !!v || 'Obligatoriskt fält'
                ],
                emailRules: [
                    v => /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
                ],
            }
        },
        computed: {
          stateUser(): UserAccount {
            return this.$store.state.userSession.userAccount
          },
        },
        watch: {
          organization(value: Organization) {
            if (value) {
              this.getOrganizationRegistrationPage();
            }
          }
        },
        created() {
          this.getOrganizationRegistrationPage();
        },
        methods: {
            async getOrganizationRegistrationPage() {
              if (this.showLinkRegistration) {
                this.loading = true;
                try {
                  let response = await get(ENDPOINTS.ORGANIZATIONS + "/" + this.organization.id + "/" + ENDPOINTS.REGISTRATION_PAGE);
                  if (response) {
                    this.organizationRegistrationPage = response;
                    this.organizationRegistrationPage.active = !this.organizationRegistrationPage.disabledStatus;
                  }
                } finally {
                  this.loading = false;
                }
              }
            },
            close() {
              this.$emit("close", true);
            }
        }
    })
