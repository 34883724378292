








































import Vue from "vue";
import {EventLogTypeArr, EventLogTypeStr, Type} from "@/interfaces/eventlog/eventLogType";

export default Vue.extend({
    props: {
        multiple: Boolean,
        label: String,
        value: [Object as () => Type, Array as () => Array<Type>],
    },
    data() {
        return {
            selectables: EventLogTypeArr,
            typeStr: EventLogTypeStr
        };
    },
    computed: {
        selected: {
            get(): Array<Type> | Type {
                return this.value
            },
            set(value: Array<Type> | Type) {
                this.$emit('update:value', value)
            }
        }
    },
    filters: {
        typeStr(type, typeStr) {
            return typeStr[type]
        }
    },
});
