





















































































import Vue from "vue";
import PasswordInputComponent from "@/components/form/PasswordInputComponent.vue";
import {Dialog} from "@/interfaces/presentation/dialog";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {pidPattern} from "@/validators";
import validator from "validator";
import Axios from "axios";
import {BASE_URL, ENDPOINTS, getService} from "@/api/mainApi";
import {LoginCredentials} from "@/interfaces/useraccount/loginCredentials";
import {UserSession} from "@/interfaces/useraccount/userSession";
import {SigningInProgressState} from "@/interfaces/bankid/signingInProgressState";
import matches = validator.matches;
import BankIdInfoComponent from "@/components/BankIdInfoComponent.vue";

export default Vue.extend({
  components: {
    BankIdInfoComponent,
    PasswordInputComponent,
  },
  data() {
    return {
      password: "",
      requiredRules: [
        v => !!v || 'Du måste ange ditt lösenord för att signera'
      ],
      requiredBankIdRules: [
        v => !!v || 'Du måste ange ditt personnummer för att signera'
      ],
      loading: false,
      bankIdDialog: false,
      errorMsg: null
    }
  },
  computed: {
    dialog(): Dialog {
      return this.$store.state.credentialsConfirmDialog
    },
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    usesBankId() {
      let soc = this.stateUser.loginCredentials.socialSecurityNumber
      return soc != null && matches(soc, pidPattern)
    },
    session(): UserSession {
      return this.$store.state.userSession
    },
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress
    },
  },
  methods: {
    async actionClick() {
      this.loading = true
      if (this.$refs.form.validate()) {
        if (await this.validCredentials()) {
          await this.dialog.actionClick()
          this.closeDialog()
        }
      }
      this.loading = false
    },
    handleDialogClosure() {
      this.removeSigning();
      this.closeDialog();
      this.dialog.closeClick();
    },
    closeDialog() {
      this.$store.commit('setCredentialsConfirmDialog', {active: false})
    },
    async validCredentials() {
      if (!this.usesBankId) {
        if (await this.usernamePasswordValid()) {
          this.errorMsg = null
          return true
        } else {
          this.errorMsg = ["Felaktigt lösenord angivet"]
          return false
        }
      } else {
        this.bankIdDialog = true
        this.closeDialog()

        try {
          let params: any = {login: false, socialSecurityNumber: this.stateUser.loginCredentials.socialSecurityNumber}
          let signingStatus: SigningInProgressState = this.signingInProgress;

          if (signingStatus) {
            // depotId is used for navigating back to the depot after signing-redirect
            if (signingStatus.depotId) {
              params.depotId = signingStatus.depotId;
            } else if (signingStatus.newKeepsakesSignatureData) {
              params.newKeepsakes = true;
            }
            else if (signingStatus.personalAgreementSigning) {
              params.personalAgreementSigning = true;
            }
          }

          let redirectResponse = await getService(ENDPOINTS.GRANDID + "/" + ENDPOINTS.SIGN, params)
          if (redirectResponse) {
            // mark the signing status as ready before redirecting away so that we know that it is time to handle the signing upon return
            signingStatus.ready = true;
            this.$store.commit('setSigningInProgress', signingStatus);

            location.href = redirectResponse.redirectUrl;
          }
        } catch (error) {
          console.log(error);
          if (error.status === 400) {
            this.errorMsg = "Felaktigt personnummer"
          } else if (error.status === 409) {
            this.errorMsg = "Det finns en annan användare med samma personnummer. Vänligen kontakta Bitnet omgående"
          } else {
            this.errorMsg = "Kunde inte öppna upp BankID. Något gick fel";
          }
        } finally {
          this.bankIdDialog = false;
        }

        this.errorMsg = ["Felaktigt personnummer angivet"]
        return false
      }
    },
    async usernamePasswordValid() {
      // backend call to authorize user
      // custom API call here to handle unauthorized when email or password is wrong
      let loginCredentials = {
        username: this.stateUser.loginCredentials.username,
        password: this.password
      } as LoginCredentials

      try {
        let response = await Axios.post(BASE_URL + "/api/" + ENDPOINTS.AUTH, loginCredentials,
            {})
        let authResponse = response.data
        if (authResponse) {
          return true
        }
      } catch (error) {
        return false
      }
    },
    setSessionInStore(userSession: UserSession) {
      this.$store.commit('updateUserSession', userSession)
    },
    removeSigning() {
      this.$store.commit('removeSigningInProgress')
    },
  }
});
