


























































































































































































































import Vue from 'vue';
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Dialog} from "@/interfaces/presentation/dialog";
import {ExternalAgent} from "@/interfaces/externalagent/externalAgent";
import {deleteRequest, ENDPOINTS, get, post, put} from "@/api/mainApi";

export default Vue.extend({
  components: {},
  data() {
    return {
      externalAgents: [] as ExternalAgent[],
      selected: null as ExternalAgent | null,
      loading: false,
      newApiKeyDialog: false,
      newApiKey: null,
      newExternalAgent: {
        name: "",
        email: "",
        apiKey: ""
      } as ExternalAgent | null,
      newDialog: false,
      emailRules: [
        v => /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
      ],
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      newFormValid: false,
      selectedFormValid: false,
    }
  },
  created() {
    this.getExternalAgents();
  },
  methods: {
    selectExternalAgent(externalAgent) {
      this.selected = externalAgent;
      this.$emit('select', externalAgent);
      this.externalAgents.forEach(o => o.active = false);
      externalAgent.active = true;
    },
    async getExternalAgents() {
      this.loading = true;
      get(ENDPOINTS.EXTERNAL_AGENTS,
      ).then((externalAgents: Array<ExternalAgent>) => {
        this.externalAgents = externalAgents;
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateExternalAgent(externalAgent: ExternalAgent) {

      let valid = this.$refs.selectedform.validate()

      if (valid) {

        put(ENDPOINTS.EXTERNAL_AGENTS + "/" + externalAgent.id, externalAgent)
          .then((ext: ExternalAgent) => {
            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "Sparade ändringar för " + ext.name,
              timeout: 8000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
            this.newDialog = false;
          }).catch(err => {
          console.log(err.data.msg);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att uppdatera API-användaren: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    confirmDisableExternalAgent(externalAgent: ExternalAgent) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Inaktivera API-användare",
        title: "Bekräfta inaktivering",
        text: "Är du säker på att du vill inaktivera " + this.selected.name + "?",
        actionClick: () => {
          this.deleteExternalAgent(externalAgent)
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    async deleteExternalAgent(externalAgent: ExternalAgent) {

      this.loading = true;

      try {
        let disabled = await deleteRequest(ENDPOINTS.EXTERNAL_AGENTS + "/" + externalAgent.id);

        if (disabled) {

          let index = this.externalAgents.findIndex((v: ExternalAgent) => v.id == externalAgent.id)
          if (index !== -1) {
            this.externalAgents.splice(index, 1);
            let snackbar: Snackbar = {
              active: true,
              color: "primary",
              text: "Inaktiverade " + externalAgent.name,
              timeout: 8000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar);
            this.selected = null;
          }
        }
      } catch (err) {
        console.log(err);
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Misslyckades med att inaktivera API-användaren: " + err.data.msg,
          timeout: 10000,
          action: null
        }
        this.$store.commit('setSnackbar', snackbar)
      } finally {
        this.loading = false;
      }
    },
    confirmGenerateNewApiKey(externalAgent: ExternalAgent) {
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Generera tredjepartsnyckel",
        title: "Bekräfta generering av ny tredjepartsnyckel",
        text: "Är du säker på att du vill generera en ny tredjepartsnyckel till " + this.selected.name + "?",
        actionClick: () => {
          this.generateNewApiKey(externalAgent)
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    async generateNewApiKey(externalAgent: ExternalAgent) {

      let apiKey = await put(ENDPOINTS.EXTERNAL_AGENTS + "/" + externalAgent.id + "/" + ENDPOINTS.API_KEY, {})
      if (apiKey) {
        externalAgent.apiKey = apiKey
        this.newApiKey = apiKey
        this.newApiKeyDialog = true
      }
    },
    addExternalAgent() {

      this.$refs.newform.validate()

      if (this.newFormValid) {

        this.loading = true;

        post(ENDPOINTS.EXTERNAL_AGENTS, this.newExternalAgent)
          .then((externalAgent: ExternalAgent) => {

            this.externalAgents.push(externalAgent);

            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: "La till " + this.newExternalAgent.name,
              timeout: 8000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar);

            this.newExternalAgent = {
              name: "",
              email: "",
              apiKey: ""
            } as ExternalAgent

            this.$store.commit('setSnackbar', snackbar);
            this.newDialog = false;

            this.newApiKey = externalAgent.apiKey
            this.newApiKeyDialog = true

          }).catch(err => {

          console.log(err.data.msg);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att lägga till API-användaren: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        }).finally(() => {
          this.loading = false;
        })
      }
    },
  }
})
