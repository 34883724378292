
















































import Vue from "vue";
import {EventLog} from "@/interfaces/eventlog/eventLog";
import DepotEventLogListComponent from "@/components/depot/details/eventlog/DepotEventLogListComponent.vue";
import DepotEventLogTypeSelectComponent from "@/components/depot/details/eventlog/DepotEventLogTypeSelectComponent.vue";
import {ENDPOINTS, get} from "@/api/mainApi";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Business} from "@/interfaces/business/business";

export default Vue.extend({
  components: {DepotEventLogListComponent, DepotEventLogTypeSelectComponent},
  data() {
    return {
      eventLogs: [] as Array<EventLog>,
      loading: false,
      panel: 1,
      filteredTypes: [],
      limit: 60
    }
  },
  props: {
    depotId: {
      type: Number,
      required: true
    },
    business: Object as () => Business,
    refreshEvents: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalNumberOfEvents() {
      return this.eventLogs.length
    },
    filteredEventLogs() {
      if (this.filteredTypes.length > 0) {
        return this.eventLogs.filter(eventLog => this.filteredTypes.includes(eventLog.eventLogType.type))
      }
      return this.eventLogs
    },
    refresh: {
      get(): Boolean {
        return this.refreshEvents
      },
      set(value: Boolean) {
        this.$emit('update:refreshEvents', false)
      }
    },
  },
  watch: {
    refreshEvents(value: Boolean) {
      if (value) {
        this.getEventLogsForDepot();
      }
    },
    business(value: Business) {
      if (value) {
        this.getEventLogsForDepot();
      }
    }
  },
  mounted() {
    this.getEventLogsForDepot()
  },
  methods: {
    async getEventLogsForDepot() {
      this.loading = true
      try {
        let params: any = { limit : this.limit }
        if (this.business) {
          params.businessId = this.business.id
        }
        let response = await get(ENDPOINTS.EVENT_LOGS + "/" + ENDPOINTS.DEPOTS + "/" + this.depotId, params)
        if(response)
        {
          this.eventLogs = response
        }
      }
      catch (e) {
        console.error(e)
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Något gick fel när händelser skulle hämtas. Kontakta support om problemet kvarstår",
          timeout: 8000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
      }
      this.loading = false
    },
    toggleExpand() {
      if (this.panel == 0) {
        this.panel = 1
      } else {
        this.panel = 0
      }
    }
  }
});
