


















































































import Vue from 'vue';
import {Business} from "@/interfaces/business/business";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {Dialog} from "@/interfaces/presentation/dialog";
import {StoragePlace} from "@/interfaces/place/storagePlace";
import {deleteRequest, ENDPOINTS, get, post, put} from "@/api/mainApi";
import {Organization} from "@/interfaces/organization/organization";
import {Keepsake} from "@/interfaces/keepsake/keepsake";
import {OrganizationRegistrationPage} from "@/interfaces/organization/organizationRegistrationPage";

export default Vue.extend({
  components: {},
  props: {
    value: Boolean,
    keepsake: Object as () => Keepsake | null,
    selectedBusiness: Object as () => Business
  },
  data() {
    return {
      places: [] as StoragePlace[],
      selected: null as StoragePlace | null,
      loading: false,
      newPlace: {
        name: "",
        description: "",
        business: null
      } as StoragePlace | null,
      newDialog: false,
      fromPlace: null,
      toPlace: null,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      newFormValid: false,
      moveFormValid: false,
      selectedFormValid: false,
    }
  },
  computed: {
    active: {
      get(): Boolean {
        return this.value
      },
      set(value: Boolean) {
        this.$emit('update:value', value)
      }
    },
    fromPlaces() {
      // Only places that have not been selected as a toPlace can be selected
      if(this.toPlace == null)
      {
        return this.places
      }
      return this.places.filter((place: StoragePlace) => place.id != this.toPlace.id)
    },
    toPlaces() {
      if(this.fromPlace == null)
      {
        return this.places
      }
      // Only places that have not been selected as a fromPlace can be selected
      return this.places.filter((place: StoragePlace) => place.id != this.fromPlace.id)
    }
  },
  watch: {
    'selectedBusiness': {
      handler(val: Business) {
        if (val && val.id) {
          this.selected = null;
          this.newPlace.business = val;
          this.getStoragePlaces(val.id);
        }
      },
      immediate: true
    },
    'keepsake': {
      handler (val: Keepsake) {
        if (val && val.id) {
          this.fromPlace = val.storagePlace;
        }
      },
      immediate: true
    },
  },
  methods: {
    async getStoragePlaces(businessId) {
      this.loading = true
      get(ENDPOINTS.BUSINESSES + "/" + businessId + "/" + ENDPOINTS.STORAGE_PLACES)
      .then((res: Array<StoragePlace>) => {
        this.places = res;
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    closeDialog() {
      this.active = false
    },
    emitTransferEvent() {
      this.$emit('transferred-keepsakes',
        {
          fromPlace: this.fromPlace,
          toPlace: this.toPlace
        });
    },
    async moveKeepsakes() {

      this.$refs.moveForm.validate();

      if (this.moveFormValid) {

        if (this.fromPlace && this.toPlace) {

          this.loading = true

          try {
            let transferRes = await put(ENDPOINTS.STORAGE_PLACES + "/" + this.fromPlace.id + "/" + ENDPOINTS.KEEPSAKES + "/" + ENDPOINTS.MOVE + "?toStoragePlaceId=" + this.toPlace.id, {});

            if (transferRes) {
              this.fromPlace.keepsakeCount = 0;
              this.toPlace.keepsakeCount += transferRes;

              this.emitTransferEvent();

              let snackbar: Snackbar = {
                active: true,
                color: "success",
                text: "Flyttade " + transferRes + " bevakningar från " + this.fromPlace.name + " till " + this.toPlace.name,
                timeout: 8000,
                action: null
              };
              this.$store.commit('setSnackbar', snackbar);
              this.fromPlace = null;
              this.toPlace = null;
              this.closeDialog();
            }
          } catch (err) {
            console.log(err);

            let snackbar: Snackbar = {
              active: true,
              color: "error",
              text: "Misslyckades med att flyta bevakningar: " + err.data.msg,
              timeout: 8000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
          }
          finally {
            this.loading = false;
          }
        } else {
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Du måste välja både till och från",
            timeout: 8000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        }
      }
    },
    async moveKeepsake() {

      this.$refs.moveForm.validate();

      if (this.moveFormValid) {

        if (this.fromPlace && this.toPlace) {

          this.loading = true

          try {
            let transferRes = await put(ENDPOINTS.STORAGE_PLACES + "/" + this.toPlace.id + "/" + ENDPOINTS.KEEPSAKES + "/" + this.keepsake.id + "/" + ENDPOINTS.MOVE, {});

            if (transferRes) {
              this.fromPlace.keepsakeCount -= 1;
              this.toPlace.keepsakeCount += 1;

              this.emitTransferEvent();

              let snackbar: Snackbar = {
                active: true,
                color: "success",
                text: "Flyttade bevakning från " + this.fromPlace.name + " till " + this.toPlace.name,
                timeout: 8000,
                action: null
              };
              this.$store.commit('setSnackbar', snackbar);
              this.fromPlace = this.toPlace;
              this.toPlace = null;
              this.closeDialog();
            }
          } catch (err) {
            console.log(err);

            let snackbar: Snackbar = {
              active: true,
              color: "error",
              text: "Misslyckades med att flyta bevakning: " + err.data.msg,
              timeout: 8000,
              action: null
            };
            this.$store.commit('setSnackbar', snackbar);
          }
          finally {
            this.loading = false;
          }
        } else {
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Du måste välja både till och från",
            timeout: 8000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
        }
      }
    }
  }
})
